import * as Backbone from 'backbone';
import _ from 'underscore';
import 'jquery';
import 'jquery-smooth-scroll';
import 'jquery-validation';
import 'chart.js';
import {} from 'jquery-ujs'
import { namespace } from 'src/namespace';
import 'src/doctible';
import 'src/jquery.mask.min.js';
import 'src/bootstrap.min.js';
import 'jquery-ui-sortable';

document.addEventListener("DOMContentLoaded", () => {
  $(function(){
    var tokenValue = $("meta[name='csrf-token']").attr('content');

    $.ajaxSetup({
      headers: {'X-CSRF-Token': tokenValue}
    });
  })


  new Doctible.Views.Body();
  $('[data-mask]').each(function() { $(this).mask($(this).data('mask')) });
  new Doctible.Views.ProviderPortal.Body();
});
